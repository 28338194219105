export default function responsiveTable() {

  $('table.cookieFonct th').each(function(i,elem) {
    var num = i + 1;
    $('table.cookieFonct td:nth-child(' + num + ')').attr('data-label', $(elem).text());
  });

  $('table.cookieOpt th').each(function(i,elem) {
    var num = i + 1;
    $('table.cookieOpt td:nth-child(' + num + ')').attr('data-label', $(elem).text());
  });

}
