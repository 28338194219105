'use strict';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;

import '../css/index.scss';
require('bootstrap');
import 'slick-carousel';
import adminMenu from '@elasticms/admin-menu';
import toc from "./toc";
import externalLink from "./external-link";
import carousel from "./carousel";
import back2top from "./back2top";
import responsiveTable from "./responsive-table";
import Cookies from 'js-cookie';
import cookiesBanner from "./cookiesBanner";

adminMenu();
back2top();
carousel();
toc();
externalLink();
responsiveTable();

$(document).ready(function() {
    cookiesBanner(Cookies);
});
