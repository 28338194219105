export default function carousel() {
    $('.cycle').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnDotsHover:false,
        pauseOnHover:false,
        dots: true,
        speed: 1000,
        fade: true,
        infinite: true,
        prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><span class="sr-only">Previous</span></button>',
        nextArrow: '<button class="slick-next" aria-label="Next" type="button"><span class="sr-only">Next</span></button>'
    });

    $('.slick-dots').append('<li class="mt-1"><div class="pause-wrapper"><button class="btn btn-sm text-white pause"><i class="fa fa-pause mr-1"></i> Pause</button></div><div class="pause-wrapper d-none"><button class="btn btn-sm text-white play"><i class="fa fa-play mr-1"></i> Play</button></div></li>');

    function removeRolePresentation() {
        $('.slick-dots li:last-child').removeAttr('role');
    }
    removeRolePresentation();
    setInterval(function(){ removeRolePresentation() }, 2500);

    $(document).on('click', '.pause', function() {
        $('.cycle').slick('slickPause');
        $('.pause-wrapper').toggleClass('d-none');
    });

    $(document).on('click', '.play', function() {
        $('.cycle').slick('slickPlay');
        $('.pause-wrapper').toggleClass('d-none');
    });

    $('.cycle-tv').slick({
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnDotsHover:false,
        pauseOnHover:false,
        dots: false,
        arrows: false,
        speed: 1000,
        fade: true,
        infinite: true
    });

}